import {
    ADD_MEMBER_COORDINATOR,
    CHANGE_PROPERTY_COORDINATOR, EDIT_MEMBER_COORDINATOR,
    GET_COORDINATOR, RECORD_GROUP_SUCCESSFUL,
    REMOVE_MEMBER,
    RESET_REGISTRATION_COORDINATOR,
    RESET_STRIPE_COORDINATOR,
    SET_ERROR_COORDINATOR, START_EDIT_COORDINATOR,
    SUBMIT_STRIPE_ERROR_COORDINATOR,
    SUBMIT_STRIPE_SUCCESS_COORDINATOR
} from "./groupAction"
import { GET_CATEGORY, RECORD_USER_SUCCESSFUL, SEND_FILES_SUCCESSFUL } from "../api/apiAction"

const initialState = {
    onEdit: false,
    formValidated: false,
    information: {
        event_idevent: 23,
        group_gender: {
            error: '',
            value: '',
            required: true,
        },
        group_lastname: {
            error: '',
            value: '',
            required: true,
        },
        group_firstname: {
            error: '',
            value: '',
            required: true,
        },

        group_mail: {
            error: '',
            value: '',
            required: true,
        },
        confirmEmail: {
            error: '',
            value: '',
            required: true,
        },
        group_phone: {
            error: '',
            value: '',
        },
        group_name: {
            error: '',
            value: '',
            required: true,
        },
        group_address: {
            error: '',
            value: '',
            required: true,
        },
        group_address2: {
            error: '',
            value: '',
            required: false,
        },
        group_zipcode: {
            error: '',
            value: '',
            required: true,
        },
        group_city: {
            error: '',
            value: '',
            required: true,
        },
        group_country: {
            error: '',
            value: '',
            required: true,
        },
        countryCode: {
            error: '',
            value: '',
            required: false,
        },
        group_vat: {
            error: '',
            value: '',
            required: false,
        },
        group_cgv: {
            error: '',
            value: false,
            required: true,
        },
        group_cgv2: {
            error: '',
            value: false,
            required: true,
        },
        group_discount: {
            error: '',
            value: false,
            required: false,
        },
        group_banktransfer_ref: {
            error: '',
            value: '',
            required: false,
        }
    },
    members: [],
    stripe: {
        error: "",
        success: false,
        publishableKey: "",
        clientSecret: "",
    },
    coordinator: null,
}

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PROPERTY_COORDINATOR:
            return {
                ...state,
                information: {
                    ...state.information,
                    [action.name]: {
                        ...state.information[action.name],
                        value: action.value
                    }
                }
            }
        case SET_ERROR_COORDINATOR:
            return {
                ...state,
                information: {
                    ...state.information,
                    [action.name]: {
                        ...state.information[action.name],
                        error: action.error
                    }
                }
            }
        case GET_CATEGORY:
            return action.isParent === false ?
                {
                    ...state,
                    category: action.category,
                }
                : state
        case RECORD_USER_SUCCESSFUL:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    publishableKey: action.data.publishableKey,
                    clientSecret: action.data.clientSecret
                }
            }
        case SUBMIT_STRIPE_SUCCESS_COORDINATOR:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    error: "",
                    success: true,
                }
            }
        case SUBMIT_STRIPE_ERROR_COORDINATOR:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    success: false,
                    error: action.error,
                }
            }
        case RESET_STRIPE_COORDINATOR:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    success: false,
                    error: "",
                }
            }
        case RESET_REGISTRATION_COORDINATOR:
            return initialState
        case SEND_FILES_SUCCESSFUL:
            return {
                ...state,
                information: {
                    ...state.information,
                    registered_file: {
                        ...state.information.registered_file,
                        value: action.uri
                    },
                }
            }
        case ADD_MEMBER_COORDINATOR:
            return {
                ...state,
                members: [...state.members.concat(action.member)]
            }
        case GET_COORDINATOR:
            return {
                ...state,
                coordinator: action.coordinator,
                members: action.coordinator.group_registered
            }
        case REMOVE_MEMBER:
            return {
                ...state,
                members: [...state.members.filter(member => member.registered.registered_mail !== action.member.registered.registered_mail)]
            }
        case START_EDIT_COORDINATOR:
            return {
                ...state,
                onEdit: true,
            }
        case RECORD_GROUP_SUCCESSFUL:
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    publishableKey: action.data.publishableKey,
                    clientSecret: action.data.clientSecret
                }
            }
        case EDIT_MEMBER_COORDINATOR:
            return {
                ...state,
                members: [...state.members.map(member => {
                    if (member.registered.registered_mail === action.member.registered.registered_mail)
                        return action.member
                    else
                        return member
                })]
            }
        default:
            return state
    }
}

export default groupReducer




