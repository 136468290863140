import {
    selectOptions,
    selectTicket
} from "../registration/registrationAction"

require('es6-promise').polyfill();
require('isomorphic-fetch');

export const
    ERROR = 'ERROR',
    GET_CATEGORIES = 'GET_CATEGORIES',
    GET_CATEGORY = 'GET_CATEGORY',
    GET_OPTIONS = "GET_OPTIONS",
    LOGIN = "LOGIN",
    LOGIN_FAILED = "LOGIN_FAILED",
    GET_USER = "GET_USER",
    REMIND_PASSWORD = "REMIND_PASSWORD",
    REMIND_PASSWORD_FAILED = "REMIND_PASSWORD_FAILED",
    CHECK_UNIQ_MAIL_SUCCESS = "CHECK_UNIQ_MAIL_SUCCESS",
    CHECK_UNIQ_MAIL_FAILED = "CHECK_UNIQ_MAIL_FAILED",
    RECORD_USER_SUCCESSFUL = "RECORD_USER_SUCCESSFUL",
    RECORD_USER_FAILED = "RECORD_USER_FAILED",
    SEND_FILES_SUCCESSFUL = "SEND_FILES_SUCCESSFUL",
    LOGIN_MEMBER = "LOGIN_MEMBER",
    LOGIN_WCTOH = "LOGIN_WCTOH"

export const uri = "https://22theunion.urevent.fr/elleestintrouvable/"
export const uri_wctoh = "https://wctoh.urevent.fr/elleestintrouvable/"
export const uri2 = "https://apidev.urevent.fr/api/"

export const getCategories = () => async dispatch => {
    try {
        const res = await fetch(uri + "categories")

        if (res.status === 200) {
            const data = await res.json();
            dispatch({
                type: GET_CATEGORIES,
                categories: data
            });
            return data
        } else {
            dispatch({ type: ERROR, error: res.status });
        }
    } catch (e) {
        dispatch({ type: ERROR, e });
    }
}

export const getCategory = (id, isParent = true) => async dispatch => {
    try {
        const res = await fetch(uri + "categories/" + id)

        if (res.status >= 200 && res.status <= 201) {
            const data = await res.json();
            dispatch({
                type: GET_CATEGORY,
                category: data,
                isParent: isParent,
            })

            return data
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
}

export const getOptionsFormTicket = (category, ticket) => async dispatch => {
    try {
        const res = await fetch(uri + "categories/" + category + "/optFilterForpack/" + ticket)

        if (res.status >= 200 && res.status <= 201) {
            const data = await res.json();
            dispatch({
                type: GET_OPTIONS,
                options: data.options
            })

            return data.options
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
}


export const login = (email, password) => async dispatch => {
    try {
        const url = uri2 + 'registered/login';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "registered_mail": email,
                "registered_password": password
            })
        });

        if (res.status === 200) {
            const data = await res.json();

            dispatch({
                type: LOGIN,
                id: data.idregistered
            });

            dispatch(getUser(data.idregistered))

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: LOGIN_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}



export const login2 = (email, password) => async dispatch => {
    try {
        const url = uri + 'registeredlogin';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "registered_mail": email,
                "registered_password": password
            })
        });

        if (res.status === 200) {
            const data = await res.json();

            dispatch({
                type: LOGIN,
                id: data.idregistered
            });

            dispatch(getUser(data.idregistered))

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: LOGIN_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const getUser = (id) => async dispatch => {
    try {
        const res = await fetch(uri2 + "registered/regsieterdfront/" + id)

        if (res.status >= 200 && res.status <= 201) {
            const data = await res.json();
            dispatch({
                type: GET_USER,
                user: data
            })

            dispatch(getCategory(data.registered.registered_category, false))
                .then(category => {
                    if (data.registered_package !== null) {
                        dispatch(selectTicket(category.package.find(ticket => ticket.idpackages === data.registered_package.rp_idpackage)))
                        dispatch(getOptionsFormTicket(category.idcategories, data.registered_package.rp_idpackage))
                            .then((optionsAPI) => {
                                optionsAPI.forEach(option => { //category.options
                                    if (data.registered_option.find(opt => opt.idoptions === option.idoptions))
                                        dispatch(selectOptions(option))
                                })
                            })
                    }
                })
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
}


export const remindPassword = (email) => async dispatch => {
    try {
        const url = uri2 + 'auth/remindpassword';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "mail": email,
                "idevent": 23,
                "type": 'registered',
            })
        });

        if (res.status === 200) {
            dispatch({
                type: REMIND_PASSWORD,
            });

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: REMIND_PASSWORD_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const checkUniqMail = (email) => async dispatch => {
    try {
        const url = uri2 + 'registered/checkuniqmail2';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "registered_mail": email,
                "idevent": "23",
            })
        });

        if (res.status === 200) {
            dispatch({
                type: CHECK_UNIQ_MAIL_SUCCESS,
            });

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: CHECK_UNIQ_MAIL_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const userFormatToAPI = (data, payment = 0) => {
    const information = data.information
    const documents = data.documents
    let body = { registered: {} }

    Object.keys(information).forEach(property => {
        if (property !== "single" && property !== "unionMember" && property !== "countryCode" && property !== "gender" && property !== "confirmEmail" && property !== "professionalCtgSelect" && property !== "profession_category" && property !== "wctohReg") {
            body.registered[property] = information[property].value !== undefined ? information[property].value : null
        }
    })

    Object.keys(documents).forEach(property => {
        if (property !== "single" && property !== "unionMember" && property !== "countryCode" && property !== "gender" && property !== "confirmEmail" && property !== "professionalCtgSelect" && property !== "profession_category" && property !== "wctohReg")
            body.registered[property] = documents[property].value !== undefined ? documents[property].value : null
    })

    if (data.category !== null)
        body.registered.registered_category = data.category.idcategories

    if (data.ticket !== null)
        body.registered_package = { idpackage: data.ticket.idpackages }

    body.registered_option = data.options.map(option => {
        return {
            idoptions: option.idoptions
        }
    })

    body.payment = payment
    return body
}

export const recordUser = (user, data, payment = 0) => async dispatch => {
    const body = userFormatToAPI(data, payment)

    try {
        const url = uri2 + 'registered/create/' + (user !== null ? user.registered.idregistered : "");
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        if (res.status === 200) {
            const data = await res.json();
            dispatch({
                type: RECORD_USER_SUCCESSFUL,
                data: data,
            });
            dispatch(onChangeByName(idreg, data.id))
            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: RECORD_USER_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}


export const recordUser2 = (user, data, payment = 0) => async dispatch => {
    const body = userFormatToAPI(data, payment)

    try {
        const url = uri + 'registered/' + (user !== null ? user.registered.idregistered : "");
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        if (res.status === 200) {
            const data = await res.json();
            dispatch({
                type: RECORD_USER_SUCCESSFUL,
                data: data,
            });

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: RECORD_USER_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const sendFilesDocuments = (files) => async dispatch => {
    try {
        const formData = new FormData()
        files.forEach(file => {
            formData.append('file', file)
        })

        const res = await fetch("https://22theunion.urevent.fr/elleestintrouvable/upload/", {
            body: formData,
            method: 'POST',
        })

        if (res.status >= 200 && res.status <= 201) {
            const data = await res.json();
            dispatch({
                type: SEND_FILES_SUCCESSFUL,
                uri: data.url_justif
            })
            return data.url_justif
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
        return false
    }
}

export const checkMember = (email) => async dispatch => {
    try {
        const url = uri + 'check_member';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email,
            })
        });

        if (res.status === 200) {
            const data = await res.json();

            dispatch({
                type: LOGIN_MEMBER,
                member: data.members
            })

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: LOGIN_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const checkWctoh = (email) => async dispatch => {
    try {
        const url = uri_wctoh + 'check_registered';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email,
            })
        });

        if (res.status === 200) {
            const data = await res.json();

            dispatch({
                type: LOGIN_WCTOH,
                wctoh: data.members
            })

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: LOGIN_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}
